body {
  margin: 0;
  padding: 0;
  font-family: Merriweather, sans-serif;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: var(--color1);
}

@font-face {
  font-family: 'Merriweather';
  src: url(./Fonts/merriweather.regular.ttf);
}
