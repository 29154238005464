:root {
  --color1: #f7f3f3;
  --color2: #64b7d5;
  --color3: #2079c7;
  --color4: #7c767e;
  --color5: #1f2334;
}

.App {
  text-align: center;
  margin-left: 10%;
}

.scrolling-div {
  overflow-y: auto;
  width: 100vw;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrolling-div-mobile {
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -20%;
  padding-left: 20%;
}

Button {
  outline: none !important;
}

#PageBox {
  display: flex;
}
