#name {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: var(--color1);
  color: var(--color1);
  font-size: 4em;
}

#occupation {
  position: absolute;
  top: 80px;
  right: 5px;
  z-index: 2;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: var(--color1);
  color: var(--color1);
  font-size: 2em;
}

#profile-picture {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
  height: 100vh;
}

#Home {
  position: relative; 
  height: 100vh;
}

@media screen and (max-width: 639px) {
  #name {
    font-size: 3.6em;
  }
}

@media screen and (max-width: 600px) {
  #name {
    top: 70px;
  }

  #occupation {
    top: 150px;
  }
}

@media screen and (max-width: 369px) {
  #name {
    font-size: 14vw;
  }
  #occupation {
    top: calc(70px + 18vw);
    font-size: 7vw;
  }
}
